<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list">
        <el-table-column label="头像" show-overflow-tooltip>
          <template v-slot="s">
            <img :src="s.row.avatar | tomedia" style="width: 3rem;height: 3rem;border-radius: .5rem">
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="昵称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="content" label="内容" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot="header">
            <el-button @click="editBox = true">添加</el-button>
          </template>
          <template v-slot="s">
            <el-button @click="edit(s.row)" type="primary">编辑</el-button>
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
    <el-dialog title="编辑" :visible.sync="editBox" @close="editClose">
      <el-form label-width="3rem" style="padding: 0 2rem">
        <el-form-item label="头像">
          <y_upload_img v-model="editForm.avatar"></y_upload_img>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="editForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input v-model="editForm.content"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import y_upload_img from '@/components/y-form/y-upload/y_upload_img'

const editFormTpl = {
  nickname: "",
  avatar: "",
  content: "",
}

export default {
  name: "comment",
  components: {
    y_upload_img,
  },
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      editBox: false,
      editForm: {...editFormTpl}
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    del({id}) {
      this.$u.api.card.plugin.homePage.videoPage.commentDel({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    edit(item) {
      this.editForm = {...item};
      this.editBox = true;
    },
    editClose() {
      this.editForm = {...editFormTpl}
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.card.plugin.homePage.videoPage.commentSearch({
        page:this.page,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
    editSubmit() {
      this.$u.api.card.plugin.homePage.videoPage.commentEdit(this.editForm).then(() => {
        this.$message.success("操作成功");
        this.editBox = false;
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>